/**
 * Each user-type page should emit the appropriate configuration to the default layout
 * to properly configure the header's banner content slot
 */

const bannerConfigurationEnum = Object.freeze({
  TITLE_ONLY: 'titleOnly',
  TITLE_AND_SEARCHBAR: 'titleAndSearchBar',
  SEARCHBAR_ONLY: 'searchBarOnly',
})

export default bannerConfigurationEnum
